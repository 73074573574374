import * as React from 'react'
import * as styles from './HeaderPage.module.scss'
import {FC} from "react";

type PropsType = {
  title: string;
}

const HeaderPage:FC<PropsType> = ({title}) => {
  return (
    <div className={styles.header}>{title}</div>
  )
}

export default HeaderPage;
