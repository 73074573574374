import * as React from "react";
import * as styles from "./Avatar.module.scss";
import { FC } from "react";
import { AttributeNFT } from "../../../context/profile/profileReducer";

type PropsType = {
  nftName: string;
  avatar: string;
  attributes: AttributeNFT[];
};

const Avatar: FC<PropsType> = ({ nftName, avatar, attributes }) => {
  const season = attributes.find((el) => el.trait_type === "Season");
  return (
    <div className={styles.avatar}>
      <img
        className={styles.image}
        width={231}
        height={313}
        src={avatar}
        alt="avatar"
      />
      <div className={styles.nftName}>
        {nftName} {season && `(Season ${season?.value})`}
      </div>
    </div>
  );
};

export default Avatar;
