import * as React from "react";
import * as styles from "./ProfileInput.module.scss";
import { FC } from "react";

type PropsType = {
  label: string;
  id: string;
  defaultValue: string;
  placeholder?: string;
  type?: string;
  rows?: number;
};

const ProfileInput: FC<PropsType> = ({
  label,
  placeholder,
  id,
  type,
  defaultValue,
  rows,
}) => {
  const isTextArea = !type || type !== "textarea";
  const style = {
    width: !isTextArea || id === "address" ? "100%" : "",
    height: !isTextArea ? "100%" : "",
  };
  return (
    <div className={styles.inputBlock} style={{ ...style }}>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      {isTextArea ? (
        <input
          style={{ ...style }}
          defaultValue={defaultValue}
          className={styles.input}
          type="text"
          id={id}
          placeholder={placeholder}
          readOnly
        />
      ) : (
        <textarea
          defaultValue={defaultValue}
          rows={rows || 3}
          className={styles.input}
          id={id}
          placeholder={placeholder}
          readOnly
        />
      )}
    </div>
  );
};

export default ProfileInput;
