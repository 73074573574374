import * as React from "react";
import * as styles from "../styles/Profile.module.scss";
import Layout from "../component/Layout";
import { Helmet } from "react-helmet-async";
import Avatar from "../component/Profile/Avatar/Avatar";
import ProfileInput from "../component/Profile/ProfileInput/ProfileIntup";
import Skeleton from "../component/Loader/Loader";
import defaultAvatar from "../images/profile/needNFT.png";
import useProfile from "../hooks/use-profile";
import { useEffect } from "react";
import Spinner from "../component/Spinner";
import NoNFT from "../component/NFTList/NoNFT";
import HeaderPage from "../component/Profile/HeaderPage";

const Profile = () => {
  const { getProfile, profile, isLoading, lastMemberNft, isSeasonTokenExists } = useProfile();

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Helmet>
        <title> Profile - Reds Club</title>
        <meta property='og:title' content='Profile  - Reds Club' />
        <meta name='description' content='Profile ' />
        <meta property='og:description' content='Profile' />
      </Helmet>
      <Layout>
        <main className={styles.container}>
          <section className={styles.myVotes}>
            {isSeasonTokenExists ? (
              <section className={styles.profile}>
                <HeaderPage title='Profile' />
                <div className={styles.content}>
                  {isLoading ? (
                    // <div className={styles.avatar}>loading</div>
                    <Skeleton width={267} height={408} />
                  ) : (
                    <Avatar
                      attributes={lastMemberNft?.attributes}
                      nftName={lastMemberNft?.name}
                      avatar={lastMemberNft?.image || defaultAvatar}
                    />
                  )}

                  <form className={styles.form}>
                    <ProfileInput
                      defaultValue={profile?.address || ""}
                      label='Address'
                      id='address'
                    />
                    <div className={styles.inputBlock}>
                      <ProfileInput
                        defaultValue={lastMemberNft?.name}
                        label='Nickname'
                        id='Nickname'
                      />
                      {lastMemberNft?.attributes
                        .filter((el) => !el.trait_type.includes("Previous season"))
                        .map(({ value, trait_type }) => (
                          <ProfileInput defaultValue={value} label={trait_type} id={trait_type} />
                        ))}
                    </div>
                    {lastMemberNft?.description && (
                      <ProfileInput
                        defaultValue={lastMemberNft?.description}
                        label={"Description"}
                        id={lastMemberNft?.tokenId}
                        type='textarea'
                      />
                    )}
                    {lastMemberNft?.attributes
                      .filter((el) => el.trait_type.includes("Previous season"))
                      .map(({ value, trait_type }) => (
                        <ProfileInput
                          defaultValue={value}
                          label={trait_type}
                          id={trait_type}
                          type='textarea'
                        />
                      ))}
                  </form>
                </div>
              </section>
            ) : (
              <div>
                <HeaderPage title='Profile' />
                {isLoading && <Spinner />}
                {!isLoading && (
                  <NoNFT title={"To create a profile you need to get personal membership NFT"} />
                )}
              </div>
            )}
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Profile;
