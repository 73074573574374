import ContentLoader from "react-content-loader";
import * as React from "react";

type TMyLoader = {
  width?: number;
  height?: number;
};

const MyLoader: React.FunctionComponent<TMyLoader> = ({ width = 361, height = 552 }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox='0 0 362 552'
    backgroundColor='#fbf9f9'
    foregroundColor='#ecebeb'
  >
    <circle cx='193' cy='49' r='3' />
    <rect x='5' y='5' rx='0' ry='0' width='426' height='574' />
  </ContentLoader>
);

export default MyLoader;
