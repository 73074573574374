// extracted by mini-css-extract-plugin
export var accordionItems = "Profile-module--accordionItems--O879C";
export var accordionSection = "Profile-module--accordionSection--qXwex";
export var button = "Profile-module--button--xdean";
export var container = "Profile-module--container--ZKC1S";
export var content = "Profile-module--content--bXaFB";
export var form = "Profile-module--form--Ellms";
export var headerResult = "Profile-module--headerResult--p76DU";
export var iconAnnoncer = "Profile-module--iconAnnoncer--yhhEV";
export var iconTrophy = "Profile-module--iconTrophy--ayhEd";
export var inputBlock = "Profile-module--inputBlock--gRMeC";
export var myNFT = "Profile-module--myNFT--Xxtc+";
export var myVotes = "Profile-module--myVotes--G9Rd+";
export var myVotesBlock = "Profile-module--myVotesBlock--B4aNe";
export var myVotesTitle = "Profile-module--myVotesTitle--pPLqq";
export var myVotesWrapp = "Profile-module--myVotesWrapp--RzlXj";
export var noVotes = "Profile-module--noVotes--I86ln";
export var profile = "Profile-module--profile--1VYiP";
export var resultBlock = "Profile-module--resultBlock--wvQj+";
export var subTitle = "Profile-module--subTitle--+GCmf";