import * as React from "react";
import * as styles from "./NoNFT.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../../common/Button";
import img from "../../../images/profile/NeedNFT.svg";

const NoNFT = ({ title }: { title: string }) => {
  return (
    <div className={styles.noNFT}>
      <div className={styles.title}>{title}</div>
      <img className={styles.image} width={176} height={233} src={img} alt='needNFT' />
      <a href='https://forms.gle/DXxUsNJ3E5m74hYv8' target='_blank'>
        <Button width='230px' height='58px'>
          GET MEMBERSHIP NFT
        </Button>
      </a>
    </div>
  );
};

export default NoNFT;
